import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import {
  checkIfMural,
  useVariantPrice,
  formatPrice,
  translateString,
} from '../utils';
import { StoreContext } from '../context/StoreContext';

const StyledPrice = styled.p`
  ${({ isProductCard }) => {
    if (isProductCard) {
      return css`
        height: 20px;
      `;
    }
  }}
`;

const Price = ({ variant, locale, isProductCard, ...props }) => {
  const { currency, currencyLoaded } = useContext(StoreContext);
  const variantPrice = useVariantPrice(variant);

  const isMural = checkIfMural(variant.sku);

  return (
    variantPrice && (
      <StyledPrice isProductCard={isProductCard} {...props}>
        {currencyLoaded && (
          <>
            <span>
              {formatPrice({
                amount:
                  variantPrice.amount *
                  (isMural ? (currency === 'USD' ? 1 : 10) : 1),
                currencyCode: variantPrice.currencyCode,
                locale: locale,
              })}
            </span>{' '}
            {isMural
              ? currency === 'USD'
                ? translateString('product.perFt', locale)
                : translateString('product.perM', locale)
              : translateString('product.perRoll', locale)}
            {isMural && <sup>2</sup>}
          </>
        )}
      </StyledPrice>
    )
  );
};

export default Price;
