import React from 'react';
import styled from 'styled-components';
import {
  brandColours,
  fontSize,
  standardColours,
  zIndexLayers,
} from '../styles';
import { Svg } from './ui';
import starIcon from '../images/star.inline.svg';

const StyledStarReview = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const StyledStars = styled.div`
  display: inline-flex;
  position: relative;
  gap: 3px;
`;

const StyledStar = styled.div``;

const StyledStarSvg = styled(Svg)`
  width: ${({ isProductCard }) => (isProductCard ? 14 : 16)}px;
  height: ${({ isProductCard }) => (isProductCard ? 14 : 16)}px;

  polygon {
    fill: ${brandColours.septenary};
  }
`;

const StyledStarCover = styled.div`
  width: ${({ width }) => width};
  background-color: ${standardColours.black};
  mix-blend-mode: color;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${zIndexLayers.first};
`;

const StyledReviewTotal = styled.p`
  display: inline-block;
  color: ${standardColours.darkerGrey};
  ${fontSize(({ isProductCard }) => (isProductCard ? 11 : 13))}

  span {
    text-decoration: underline;
    cursor: ${({ isProductCard }) => (isProductCard ? 'default' : 'pointer')};
  }
`;

const StarReview = ({ value, total, isProductCard, ...props }) => {
  const percentage = Math.round((value / 5) * 100);

  return (
    <StyledStarReview {...props}>
      <StyledStars>
        {[...Array(5)].map((_, i) => (
          <StyledStar key={i}>
            <StyledStarSvg image={starIcon} isProductCard={isProductCard} />
          </StyledStar>
        ))}
        <StyledStarCover width={`${100 - percentage}%`} />
      </StyledStars>
      <StyledReviewTotal
        onClick={() => {
          !isProductCard &&
            setTimeout(() => {
              document
                .getElementById('reviews')
                .scrollIntoView({ behavior: 'smooth' });
            }, 100);
        }}
        isProductCard={isProductCard}
      >
        (<span>{total}</span>)
      </StyledReviewTotal>
    </StyledStarReview>
  );
};

export default StarReview;
