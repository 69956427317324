import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  maxBreakpointQuery,
  minBreakpointQuery,
  sectionMargins,
  standardColours,
  standardTransition,
} from '../styles';
import { Button, Container, Heading } from './ui';
import ProductFilters from './ProductFilters';
import ProductCard from './ProductCard';

const StyledProductListing = styled.section`
  ${({ alt }) => {
    if (alt) {
      return css`
        ${sectionMargins(undefined, '70px')};
      `;
    } else {
      return css`
        margin: -30px 0 40px;

        ${minBreakpointQuery.small`
          margin-bottom: 50px;
        `}

        ${minBreakpointQuery.large`
          margin-bottom: 60px;
        `}
      `;
    }
  }}
`;

const StyledProductFilterControls = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  ${minBreakpointQuery.large`
    margin-bottom: 30px;
  `}
`;

const StyledToggle = styled.div`
  display: flex;
  align-items: center;

  ${maxBreakpointQuery.medium`
    flex-basis: 100%;
  `}
`;

const StyledToggleText = styled.p`
  display: inline-block;
`;

const StyledToggleWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 45px;
  height: 24px;
  margin: 0 10px;
`;

const StyledToggleControl = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${standardColours.black};
  border-radius: 34px;

  &:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: ${standardColours.white};
    transition: ${standardTransition('transform')};
    border-radius: 50%;
  }
`;

const StyledToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked {
    + ${StyledToggleControl} {
      background-color: ${standardColours.black};

      &:before {
        transform: translateX(21px);
      }
    }
  }
`;

const StyledButton = styled(Button)`
  padding: 8px 38px;
  color: ${standardColours.black};
  background-color: ${standardColours.white};

  &:hover {
    color: ${standardColours.white};
    background-color: ${standardColours.black};
  }
`;

const StyledHeading = styled(Heading)`
  margin-bottom: 25px;
  text-align: center;

  ${minBreakpointQuery.small`
    margin-bottom: 35px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 45px;
  `}
`;

const StyledItems = styled.div`
  display: grid;
  gap: 30px 15px;

  ${({ isProductPage }) => {
    if (isProductPage) {
      return css`
        grid-template-columns: repeat(2, 1fr);

        ${minBreakpointQuery.mlarge`
          grid-template-columns: repeat(4, 1fr);
        `}
      `;
    } else {
      return css`
        ${minBreakpointQuery.tsmall`
          grid-template-columns: repeat(2, 1fr);
        `}

        ${minBreakpointQuery.medium`
          grid-template-columns: repeat(3, 1fr);
        `}
      `;
    }
  }}
`;

const ProductListing = ({
  heading,
  items: initialItems,
  category,
  showFilters,
  isAltCategoryPage,
  isProductPage,
}) => {
  const [listingDesignView, setListingDesignView] = useState(false);
  const [isProductFiltersOpen, setProductFiltersOpen] = useState(false);
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const toggleProductFiltersOpen = () => {
    setProductFiltersOpen(!isProductFiltersOpen);
  };

  return (
    items &&
    items.length > 0 && (
      <StyledProductListing alt={isAltCategoryPage || isProductPage}>
        <Container>
          {showFilters && (
            <>
              <StyledProductFilterControls>
                <StyledToggle>
                  <StyledToggleText>Room</StyledToggleText>
                  <StyledToggleWrapper>
                    <StyledToggleInput
                      type="checkbox"
                      onClick={() => {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({
                          event: listingDesignView
                            ? 'room-view-active'
                            : 'design-view-active',
                        });

                        setListingDesignView(!listingDesignView);
                      }}
                    />
                    <StyledToggleControl />
                  </StyledToggleWrapper>
                  <StyledToggleText>Design</StyledToggleText>
                </StyledToggle>
                <StyledButton
                  onClick={() => {
                    toggleProductFiltersOpen();

                    if (!isProductFiltersOpen) {
                      window.dataLayer = window.dataLayer || [];
                      window.dataLayer.push({ event: 'filters-opened' });
                    }
                  }}
                >
                  Filter
                </StyledButton>
              </StyledProductFilterControls>
              <ProductFilters
                initialProducts={initialItems}
                setProducts={setItems}
                isProductFiltersOpen={isProductFiltersOpen}
                toggleProductFiltersOpen={toggleProductFiltersOpen}
              />
            </>
          )}
          {heading && <StyledHeading>{heading}</StyledHeading>}
          <StyledItems isProductPage={isProductPage}>
            {items.map((item, id) => (
              <ProductCard
                key={id}
                id={item.id}
                title={item.title}
                page={item}
                featuredImage={item.featuredImage}
                cardImages={{
                  cardImageOne: item.cardImageOne,
                  cardImageTwo: item.cardImageTwo,
                }}
                description={item.description}
                metafields={item.metafields}
                variants={item.variants}
                category={category}
                categoryOverrides={item.productCategoryOverrides}
                reviews={item.reviews}
                locale={item.locale}
                listingDesignView={listingDesignView}
                isProductPage={isProductPage}
              />
            ))}
          </StyledItems>
        </Container>
      </StyledProductListing>
    )
  );
};

export default ProductListing;
