import React from 'react';
import styled from 'styled-components';
import { standardColours, visuallyHidden } from '../styles';
import { getColourHex } from '../utils';

const StyledColourSwatches = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;
`;

const StyledItem = styled.li`
  margin: 4px 4px 4px 0;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledColourText = styled.span`
  position: relative;
  display: block;
  height: 16px;
  width: 16px;
  background-color: ${({ colour }) =>
    colour ? colour : standardColours.transparent};
  border: 2px solid ${standardColours.white};
  border-radius: 50%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  span {
    ${visuallyHidden()};
  }
`;

const ColourSwatches = ({ variants, ...props }) => {
  const colours = [
    ...new Set(
      variants.map(product => getColourHex(product.metafields))
    ),
  ];

  return (
    colours.length > 0 && (
      <StyledColourSwatches {...props}>
        {colours.map((colour, id) => (
          <StyledItem key={id}>
            <StyledColourText colour={colour}>
              <span>{colour}</span>
            </StyledColourText>
          </StyledItem>
        ))}
      </StyledColourSwatches>
    )
  );
};

export default ColourSwatches;
