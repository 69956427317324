import React, { useState } from 'react';
import { useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  fontSize,
  fontWeights,
  minBreakpointQuery,
  standardColours,
  visuallyHidden,
} from '../styles';
import { checkIfMural, slugify } from '../utils';
import PopOutSidebar, { StyledContent, StyledFooter } from './PopOutSidebar';
import { Button } from './ui';

const StyledProductFilters = styled(PopOutSidebar)`
  right: 0;
  overflow: unset;
`;

const StyledForm = styled.form`
  overflow: scroll;
`;

const StyledInner = styled.div``;

const StyledGroups = styled.div`
  display: grid;
  height: calc(100% - 100px);
`;

const StyledGroup = styled.fieldset`
  padding: 0;
  border: none;
  margin: 15px 0;
`;

const StyledHeading = styled.legend`
  float: left;
  margin-bottom: 20px;
  padding: 0;
  width: 100%;
`;

const StyledOption = styled.div`
  margin-top: 16px;
  clear: both;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledInput = styled.input`
  ${visuallyHidden()};

  &:checked {
    + label {
      &:after {
        background-color: ${standardColours.black};
      }
    }
  }

  ${({ isAll }) => {
    if (!isAll) {
      return css`
        &:disabled {
          + label {
            color: ${standardColours.darkGrey};

            &:before {
              border: 2px solid ${standardColours.darkGrey};
            }

            &:after {
              background: none;
            }
          }
        }
      `;
    }
  }}
`;

const StyledLabel = styled.label`
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid ${standardColours.black};
  }

  &:after {
    left: 6px;
    top: 6px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${standardColours.white};
  }
`;

const StyledText = styled.span`
  margin: auto 0 auto 30px;
  ${fontSize(12)};
  line-height: 1.5;

  ${minBreakpointQuery.small`
    ${fontSize(14)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(16)};
  `}
`;

const StyledFooterInner = styled.div`
  text-align: center;
`;

const StyledClearFilters = styled.button`
  background: none;
  border: none;
  border-bottom: 0.5px solid ${standardColours.white};
  color: ${standardColours.white};
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-weight: ${fontWeights.regular};
  color: ${standardColours.black};
  background-color: ${({ canApplyFilter }) =>
    canApplyFilter ? standardColours.white : standardColours.grey};
  border: 2px solid
    ${({ canApplyFilter }) =>
      canApplyFilter ? standardColours.black : standardColours.white};

  &:hover {
    &:disabled {
      background-color: ${standardColours.grey};
    }
  }
`;

const ProductFilters = ({
  initialProducts,
  setProducts,
  isProductFiltersOpen,
  toggleProductFiltersOpen,
}) => {
  const [canApplyFilter, setCanApplyFilter] = useState(false);
  const [defaults, setDefaults] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState(initialProducts);
  const [availableFilters, setAvailableFilters] = useState();

  const productTypeFilter = [];
  const colourFilter = [];

  // Get available product types (mural / pattern) from initialProducts. Do a check in the render to see if all products are just one type.
  // Get available colours from initialProducts

  initialProducts.forEach(({ options, variants }) => {
    options
      .filter(option => option.name === 'Color')[0]
      .values.map(value =>
        colourFilter.push(value.replace(/(^\w|\s\w)/g, m => m.toUpperCase()))
      );
    // check each product if it's a mural else it's a pattern. If this array has a length of 1 then don't show this filter option
    const isMural = checkIfMural(variants[0].sku);
    if (isMural) {
      productTypeFilter.push('Mural');
    } else {
      productTypeFilter.push('Pattern');
    }
  });

  const filters = [
    {
      name: 'pattern-murals',
      displayName: 'Filter patterns / murals',
      display: new Set(productTypeFilter).size > 1,
      items: [
        { text: 'Show patterns and murals', value: 'All' },
        ...new Set(productTypeFilter),
      ],
      type: 'radio',
    },
    {
      name: 'colours',
      displayName: 'Filter products available in;',
      display: true,
      items: [
        { text: 'All colours', value: 'all-colours' },
        ...new Set(colourFilter),
      ],
      type: 'checkbox',
    },
  ];

  const setDefaultFilters = () => {
    let filterDefaults = [];

    filters.map(filter => {
      filterDefaults.push(filter.name);
      return filter;
    });

    setDefaults(filterDefaults);
  };

  useEffect(() => {
    setDefaultFilters();
  }, []);

  const handleChange = e => {
    const form = e.currentTarget;
    const formData = new FormData(form);

    const selectedProductType = formData.get('pattern-murals')
      ? formData.get('pattern-murals')
      : 'All';
    const selectedColours = formData.getAll('colours');

    // remove checkbox default selected
    if (selectedColours.length > 0 && defaults.includes('colours')) {
      defaults.splice(defaults.indexOf('colours'), 1);
    } else if (
      selectedColours.includes('all-colours') &&
      !defaults.includes('colours')
    ) {
      defaults.push('colours');
    }

    if (selectedProductType !== 'All' && defaults.includes('pattern-murals')) {
      defaults.splice(defaults.indexOf('pattern-murals'), 1);
    } else if (
      selectedProductType === 'All' &&
      !defaults.includes('pattern-murals')
    ) {
      defaults.push('pattern-murals');
    }

    setDefaults(defaults);

    // Enable any usable filters after the filtering, colours will always be available if product type matches
    const filters = [];

    const filteredInitialProducts = initialProducts.filter(
      ({ options, variants }) => {
        const isMural = checkIfMural(variants[0].sku);

        let colourOptions = options.filter(option => option.name === 'Color')[0]
          .values;

        colourOptions.map(value => {
          if (selectedProductType === 'Mural' && isMural) {
            if (
              selectedColours.length === 0 ||
              selectedColours.includes('all-colours')
            ) {
              // Leave both options available
              filters.push('Mural');
              filters.push('Pattern');
            } else {
              filters.push('Mural');
            }
            return filters.push(
              value.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
            );
          } else if (selectedProductType === 'Pattern' && !isMural) {
            if (
              selectedColours.length === 0 ||
              selectedColours.includes('all-colours')
            ) {
              filters.push('Mural');
              filters.push('Pattern');
            } else {
              filters.push('Pattern');
            }
            return filters.push(
              value.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
            );
          } else if (selectedProductType === 'All') {
            // See if we can still filter by every product type
            if (
              selectedColours.includes(value) ||
              selectedColours.includes('all-colours') ||
              selectedColours.length === 0
            ) {
              filters.push(isMural ? 'Mural' : 'Pattern');
            }

            return filters.push(
              value.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
            );
          }
          return value;
        });

        // Convert options to same casing as filter values (issue with consistancy in Shopify)
        colourOptions = colourOptions.map(colour =>
          colour.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
        );

        return (
          (selectedColours.length > 0 &&
          !selectedColours.includes('all-colours')
            ? selectedColours.some(item => colourOptions.includes(item))
            : true) &&
          (selectedProductType === 'Mural'
            ? isMural
            : selectedProductType === 'Pattern'
            ? !isMural
            : true)
        );
      }
    );

    setAvailableFilters(filters);
    setFilteredProducts(filteredInitialProducts);

    // Set this to false once the form has been submitted
    setCanApplyFilter(true);
  };

  const handleSubmit = e => {
    e.preventDefault();

    setProducts(filteredProducts);

    toggleProductFiltersOpen();
    setCanApplyFilter(false);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ event: 'filter-applied' });
  };

  return (
    <StyledProductFilters
      heading="Filter Category"
      isOpen={isProductFiltersOpen}
      toggleOpen={toggleProductFiltersOpen}
    >
      <StyledForm
        onSubmit={e => handleSubmit(e)}
        onChange={e => handleChange(e)}
      >
        <StyledInner>
          <StyledContent>
            <StyledGroups>
              {filters.map(
                ({ name, displayName, display, items, type }, id) => {
                  return (
                    display && (
                      <StyledGroup key={id}>
                        <StyledHeading>{displayName}</StyledHeading>
                        {items.map((item, id) => {
                          const itemValue =
                            item && item.value ? item.value : item;
                          const itemText = item && item.text ? item.text : item;

                          const identifier = slugify(itemValue);
                          return (
                            <StyledOption key={id}>
                              <StyledInput
                                type={type}
                                id={identifier}
                                name={slugify(name)}
                                value={itemValue}
                                isAll={id === 0}
                                checked={
                                  defaults.includes(slugify(name)) && id === 0
                                    ? true
                                    : defaults.includes(slugify(name)) &&
                                      id !== 0
                                    ? false
                                    : !defaults.includes(slugify(name)) &&
                                      id === 0
                                    ? false
                                    : undefined
                                }
                                disabled={
                                  type === 'checkbox' &&
                                  defaults.includes(slugify(name)) &&
                                  id === 0
                                    ? true
                                    : id !== 0 &&
                                      availableFilters &&
                                      !availableFilters.includes(itemValue)
                                }
                              />
                              <StyledLabel for={identifier}>
                                <StyledText>{itemText}</StyledText>
                              </StyledLabel>
                            </StyledOption>
                          );
                        })}
                      </StyledGroup>
                    )
                  );
                }
              )}
            </StyledGroups>
          </StyledContent>
        </StyledInner>
        <StyledFooter>
          <StyledFooterInner>
            <StyledClearFilters
              type="button"
              onClick={() => {
                setDefaultFilters();
                setProducts(initialProducts);
                setAvailableFilters();
                toggleProductFiltersOpen();
                setCanApplyFilter(false);
              }}
            >
              Clear all filters X
            </StyledClearFilters>
            <StyledButton
              canApplyFilter={canApplyFilter}
              disabled={!canApplyFilter}
              type="submit"
            >
              Apply selected filters
            </StyledButton>
          </StyledFooterInner>
        </StyledFooter>
      </StyledForm>
    </StyledProductFilters>
  );
};

export default ProductFilters;
